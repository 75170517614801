.login-main {
    height: calc(100vh - 82px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
}

.login-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-left img {
    width: 300px;
}

.login-right {
    background: #FFFFFF;
    padding: 2rem 1.4rem;
    width: 71%;
}

.login-r-logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-r-logo-box img {
    width: 100px;
    margin-bottom: 1rem;
}

.login-r-logo-box .lLogoBoxP1 {
    font-size: 13px;
    color: #ED303B;
    margin-bottom: 0;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 7px;
}

.login-r-logo-box .lLogoBoxP2 {
    font-size: 12px;
    color: #626262;
    margin-bottom: 0;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 11px;
}

.lLogoBoxTitle {
    font-size: 16px;
    color: #001140;
    margin: .8rem 0 1rem;
    font-weight: 600;
    text-transform: capitalize;
}

.LoginInputBox {
    margin-bottom: 1.1rem;
}

.lInputIcoGroup {
    position: relative;
}

.loginInputIcon {
    position: absolute;
    top: 4px;
    left: 7px;
}

.passwordIconBox {
    position: absolute;
    top: 4px;
    right: 11px;
}

.passwordIconBox svg {
    font-size: 14px;
    color: #6e6e6e;
}

.loginInputIcon::after {
    position: absolute;
    content: "";
    width: 1px;
    background-color: #D9D9D9;
    height: 13px;
    top: 6px;
    right: -9px;
}

.loginInputIcon svg {
    width: 18px;
}

.loginCommonInput {
    border: 1px solid #DEE8EC !important;
    color: #626262 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding-left: 3rem !important;
    padding-right: 1rem !important;
    border-radius: 4px !important;
    outline: none !important;
}

.loginCommonInput:focus {
    box-shadow: none !important;
}

.loginCheckboxSec {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.loginCheckboxSec input {
    height: 12px;
}

.loginCheckboxSec span {
    font-size: 12px;
    color: #626262;
    font-weight: 400;
    text-transform: capitalize;
}

.loginBtnBox {
    margin-top: .8rem;
}

.loginBtn1 {
    background-color: #1877F2 !important;
    width: 100%;
    color: #fff !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    font-size: 13px !important;
    margin-bottom: .8rem;
}

.loginBtn2 {
    background-color: transparent !important;
    width: 100%;
    border: .5px solid #1876f27b !important;
    color: #1877F2 !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    font-size: 13px !important;
    margin-bottom: .8rem;
}


/* media query */

@media (min-width: 1600px) {
    /* .login-left img {
        width: 485px;
    } */
    .login-right {
        width: 54%;
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .login-right {
        width: 90%;
    }
    /* .login-left img {
        width: 370px;
    } */
}

@media (min-width: 768px) and (max-width: 991px) {
    .login-left {
        display: none;
    }
    .login-right {
        width: 55%;
    }
}

@media (max-width: 767px) {
    .login-left {
        display: none;
    }
    .login-right {
        width: 100%;
    }
    .header-main {
        display: none;
    }
}

@media (max-width: 320px) and (max-width: 478px) {
    .login-r-logo-box .lLogoBoxP2 {
        text-align: center;
    }
}