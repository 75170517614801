.auditOverLay {
    position: absolute;
    height: calc(100vh - 43px);
    width: 100%;
    top: 0;
    background-color: #2828286d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auditMessageBox {
    height: 200px;
    width: 500px;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.auditMessageBox p {
    font-size: 16px;
    color: #001140;
    font-weight: 500;
    text-transform: capitalize;
}

.auditMessageBox button {
    background-color: #001140 !important;
    color: #fff !important;
    font-size: 12px !important;
    border-radius: 3px !important;
}