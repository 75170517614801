.adminHomeMain {
    height: calc(100vh - 42px);
    display: flex;
}

.downloadSamplePdfFileBox {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.downloadSamplePdfFileBox a {
    background-color: #ED303B !important;
    font-size: 13px !important;
    color: #fff !important;
    border-radius: 3px !important;
}

.pdfUploadMain {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    /* flex-direction: column; */
}

.pdfUploadForm {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 50%;
}

.pdfUploadForm input {
    border-radius: 3px !important;
    font-size: 14px !important;
    outline: none !important;
    user-select: none;
}

.pdfUploadForm input:focus {
    box-shadow: none !important;
}

.pdfUploadForm button {
    font-size: 14px !important;
    background-color: #001140 !important;
    color: #fff !important;
    border-radius: 3px !important;
}

.exportSelectInput {
    border-radius: 3px !important;
    font-size: 15px !important;
}

.exportSelectInput:focus {
    box-shadow: none !important;
}