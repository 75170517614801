.home-main {
    height: calc(100vh - 42px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.dashboardIconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ED303B;
    height: 250px;
    width: 100%;
    border-radius: 7px;
}

.dashboardCard {
    cursor: pointer;
}

.dashboardCard p {
    font-size: 17px;
    color: #000000;
    margin: .5rem 0;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}

.homePageBackBtn {
    position: absolute;
    top: 18px;
    left: 130px;
    background: #001140;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.homePageBackBtn:hover {
    background: #001140ed;
}

.homePageBackBtn svg {
    color: #fff !important;
}