.header-main {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1877F2;
    height: 42px;
    position: relative;
}

.header-left-box {
    background: #001140;
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
}

.header-left-box p {
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    /* white-space: nowrap;
    overflow: hidden;
    animation: typingAnimation 3s steps(100) infinite; */
}


/* 
@keyframes typingAnimation {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
} */

.header-right-box {
    background: #1877F2;
    position: relative;
    height: 42px;
    display: flex;
    align-items: center;
}

.header-right-box::before {
    content: '';
    position: absolute;
    left: -50px;
    z-index: 1;
    border-left: 50px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 115px solid #1877F2;
    bottom: 0;
}

.header-right-box p {
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
}

.header-right-box p span {
    font-weight: 300;
}

.logOutHeaderBox {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    gap: .3rem;
    cursor: pointer;
}

.logOutHeaderBox svg {
    width: 18px;
}

.logOutHeaderBox span {
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
}

.userNameHeader {
    position: absolute;
    right: 10%;
    font-size: 14px !important;
    color: #FFFFFF !important;
    margin-bottom: 0 !important;
    font-weight: 500 !important;
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-left-box p {
        font-size: 11px;
    }
    .header-right-box p {
        font-size: 11px;
    }
}