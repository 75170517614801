.dcaf-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  height: calc(100vh - 42px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-wrapper {
  /* position: relative;
    height: 100px;
    width: 300px;
    margin: 0 auto; */
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.chart-container {
  width: 300px;
  height: 300px;
}

.stage-box {
  text-align: center;
  margin-bottom: 20px;
}

.stage-box p span {
  font-weight: 600;
}

.start-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.stage-success-title {
  color: #06930c;
}

.details {
  margin-top: 20px;
  font-size: 18px;
}

.stage-box .backBtn {
  background-color: #001140 !important;
  color: #fff !important;
  font-size: 12px !important;
  border-radius: 3px !important;
}

.status-message {
  font-size: 15px;
  color: red;
}

.checkbox-verify-id {
  margin-bottom: 10px;
}

.checkbox-verify-id input {
  margin-right: 10px;
}
.checkbox-verify-id label {
  font-weight: 700;
}
