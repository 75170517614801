.qcMainSec {
    margin: 3rem 2rem;
}
.qcHeading{
    text-align: center;
}
.qcHeading_tab{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.exportSelectInput{
    appearance: auto !important;
}
.select_vale_qc_heading{
    width: 25%;
    margin-block-end: 15px;
}