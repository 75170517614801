.home-main {
    height: calc(100vh - 42px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.MainCardIconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #001140;
    border: 1px solid gray;
    height: 100px;
    width: 100%;
    border-radius: 7px;
}

.MainCardIconBox p {
    font-size: 22px;
    color: #fff;
    margin: .5rem 0;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
}