@import '@react-pdf-viewer/core/lib/styles/index.css';
@import '@react-pdf-viewer/default-layout/lib/styles/index.css';
.imageAuditMain {
    height: 35px;
    background: #001140;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0rem 3rem; */
    position: relative;
}

.homeNavigateBox svg {
    position: absolute;
    color: #fff !important;
    font-size: 20px;
    z-index: 999;
    left: 39px;
    top: 7px;
    cursor: pointer;
}

.detailMain {
    position: relative;
}

.pdfViewerTop .react-pdf__message--error {
    display: none !important;
}


/* .pdfViewerMain {
    width: 100%;
} */


/* .pdfViewerTop .react-pdf__Page__textContent,
.pdfViewerTop .react-pdf__Page__annotations {
    display: none !important;
} */

.imagesAuditCol9 {
    width: 73% !important;
}

.imagesAuditCol3 {
    width: 27% !important;
}

.imageAuditMain p {
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
}

.pdfViewerTop {
    height: 73vh;
    overflow-y: scroll;
    border: .5px solid #d9d9d95e;
    padding: .1rem .3rem;
}

.pdfViewerBottom {
    padding: .8rem 3rem .8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p_v_b_left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.p_v_b_left span {
    font-size: 13px;
    color: #001140;
    font-weight: 600;
    text-transform: capitalize;
}

.p_v_b_left div.box,
.p_v_b_right div.box {
    border: 1px solid #d9d9d9;
    width: 35px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #464646;
    border-radius: 3px;
    user-select: none;
}

.p_v_b_right {
    display: flex;
    align-items: center;
    gap: .8rem;
}

.p_v_b_right .p_v_b_r_btn {
    background-color: #001140 !important;
    width: 48px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.d_r_box_1 p {
    font-size: 13px;
    color: #001140;
    font-weight: 600;
    margin-bottom: 2px;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
}

.d_r_box_1 p span {
    font-weight: 500;
}

.d_r_box_1_sub_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.d_r_box_1_input_para {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.detail_d_r_box_1_checkBoxInput {
    height: 13px;
}

.detail_d_r_box_1_input {
    width: 130px !important;
    height: 30px !important;
    border-radius: 3px !important;
    outline: none !important;
    font-size: 13px !important;
}

.detail_d_r_box_1_input:focus {
    box-shadow: none !important;
}

.actDtPara {
    margin-right: 3.1rem;
}

.imageAuditHr {
    color: #b9b9b9;
    margin: .4rem 0;
}

.d_r_remark_title {
    font-size: 13px;
    color: #001140;
    font-weight: 600;
    margin-bottom: 2px;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
}

.d_r_remark_subBox {
    height: 22vh;
    overflow: scroll;
    width: 100%;
    border: .5px solid #d9d9d95e;
    padding: .1rem .3rem;
}

.remark_input_box {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.remark_input_box input {
    height: 13px;
}

.remark_input_box label {
    font-size: 13px;
    color: #282828;
    font-weight: 400;
    margin-bottom: 0px;
    /* text-transform: capitalize; */
    width: 100%;
    text-wrap: nowrap;
}

.remark_input_box:hover label {
    color: #fff;
    background-color: #1877F2;
}

.remark_input_box label:focus {
    color: #fff;
    background-color: #1877F2;
}

.selectedRemarkBox p {
    font-size: 13px;
    color: #001140;
    font-weight: 600;
    margin: .4rem 0;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
}

.selectedRemarkBox input {
    height: 40px;
    border-radius: 3px !important;
    outline: none !important;
    font-size: 13px !important;
}

.selectedRemarkBox input:focus {
    box-shadow: none !important;
}

.selectedRemarkBox textarea {
    height: 40px;
    border-radius: 3px !important;
    outline: none !important;
    font-size: 13px !important;
}

.selectedRemarkBox textarea:focus {
    box-shadow: none !important;
}

.imageAuditAcceptRejectBtnBox {
    display: flex;
    gap: .6rem;
    align-items: center;
    margin: .8rem 0;
}

.acceptBtn {
    width: 100% !important;
    background-color: #008000 !important;
    color: #fff !important;
    font-size: 13px !important;
    border-radius: 3px !important;
}

.rejectBtn {
    width: 100% !important;
    background-color: #FF0000 !important;
    color: #fff !important;
    font-size: 13px !important;
    border-radius: 3px !important;
}

.rotateIcon {
    font-size: 28px;
    color: #fff;
    padding: 2px;
}


/* media query */

@media (min-width: 1600px) {
    .pdfViewerTop {
        height: 80vh;
    }
    .d_r_box_1 p,
    .d_r_remark_title,
    .selectedRemarkBox p {
        font-size: 15px;
        margin-bottom: 5px;
    }
    .selectedRemarkBox input {
        height: 60px;
    }
    .acceptBtn,
    .rejectBtn {
        font-size: 15px !important;
    }
    .d_r_remark_subBox {
        height: 40vh;
    }
    .remark_input_box label {
        font-size: 15px;
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .imagesAuditCol9 {
        width: 70% !important;
    }
    .imagesAuditCol3 {
        width: 30% !important;
    }
    .pdfViewerTop {
        height: 80vh;
    }
    .d_r_remark_subBox {
        height: 42vh;
    }
}