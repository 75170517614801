.auditorMain {
    height: 100vh;
    display: flex;
}

.auditorDataTableBox {
    margin-top: 5rem;
}

.dataTableActionTab {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.dataTableActionTabBox {
    cursor: pointer;
}

.dataTableActionTabBox svg {
    font-size: 16px;
}

.dataTableActionTabBox .deleteIcon {
    color: rgba(255, 0, 0, 0.768);
}

.auditorTitleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0rem;
}

.auditorTitleBox button {
    background-color: #001140 !important;
    border-radius: 3px !important;
    font-size: 13px !important;
    color: #fff !important;
}

.auditorMainFormSec {
    display: flex;
    justify-content: center;
    align-items: center;
}

.addAuditorForm {
    width: 70%;
}

.auditorGroup {
    margin-bottom: 1rem;
}

.auditorGroup label {
    display: block;
    font-size: 14px !important;
    color: #001140;
    font-weight: 500;
    margin-bottom: .3rem;
}

.auditorGroup input {
    border-radius: 3px !important;
    outline: none !important;
    font-size: 13px !important;
}

.auditorGroup input:focus {
    box-shadow: none !important;
}

.auditorBtnGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.auditorBtnGroup button {
    background-color: #001140 !important;
    border-radius: 3px !important;
    font-size: 13px !important;
    color: #fff !important;
}