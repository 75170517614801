body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

:root {
    --bg-color: #FF7803;
}