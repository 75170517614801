.footer-main {
    height: 40px;
    background-color: #282828;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-box p {
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: 400;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    text-align: center;
}


/* media query  */

@media (min-width: 768px) and (max-width: 991px) {
    .footer-box p {
        font-size: 11px;
    }
}

@media (max-width: 479px) {
    .footer-box p {
        font-size: 10px;
    }
}